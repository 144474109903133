import React, { useEffect, useState } from 'react'
import "./Requests.css"
import { NavLink, useNavigate } from 'react-router-dom'
import axiosInstance from '../../../hooks/axiosInstance'
import useAuth from '../../../hooks/useAuthContext'
import ViewMap from '../../../components/Modal/DeliveryMap/ViewMap'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { toast } from 'react-toastify'
import Loader from '../../../components/Utilities/Loader/Loader'
import DeliveryVehicle from '../../../components/Modal/Supplier/Vehicle/SelectDeliveryVehicle'
import Pusher from 'pusher-js';

export const Requests = () => {
    const appKey =  process.env.NODE_ENV !== 'production' ? 
            process.env.REACT_APP_DEV_PUSHER_KEY :
                process.env.REACT_APP_PROD_PUSHER_KEY

    const appCluster =  process.env.NODE_ENV !== 'production' ? 
                            process.env.REACT_APP_DEV_PUSHER_CLUSTER :
                                process.env.REACT_APP_PROD_PUSHER_CLUSTER

    const {userData} =  useAuth()
    const navigate = useNavigate()

    const [loading, setLoading]  = useState(false)
    const [pendingOrder, setPendingOrder] = useState([])
    const [showMap, setShowMap] = useState(false);
    const [currentOrder, setCurrentOrder] = useState(null)
    const [acceptOpen, setAcceptOpen] = useState(false)
    const [showDeliveryVehicle, setShowDeliveryVehicle] = useState(false)

    const closeModal = () => {
        setShowMap(false);
        setShowDeliveryVehicle(false)
    };

    const closeOrderAccept = () => {
        setAcceptOpen(false);
    };

    const openOrderAccept = async(order) => {
        await setCurrentOrder(order)
        setAcceptOpen(true);
    };

    const showDetailsMap = async(order) => {
        await setCurrentOrder(order)
        setShowMap(true)
    }

    const handleOrderAccept = () => {
        setAcceptOpen(false)
        setShowDeliveryVehicle(true)
    }

    const fetchAllPendingOrders = () => {
        setLoading(true)
        axiosInstance.get('/user/order/pending_orders')
                    .then(res => {
                        setPendingOrder(res.data.data)
                    })
                    .catch(err =>  console.error(err.response))
                    .finally(() => setLoading(false))
    }

    useEffect(()  => {
        fetchAllPendingOrders()
    }, [])

    useEffect(() => {
        const pusher = new Pusher(appKey, {
          cluster: appCluster, // If applicable
        });
    
        const channel = pusher.subscribe('order-created'); // Subscribe to your custom channel
    
        channel.bind('order.created', (data) => { // Listen for the custom event
        //   setOrderData(data); // Update state with received data
            fetchAllPendingOrders()
        });
    
        return () => {
          pusher.disconnect(); // Disconnect on component unmount
        };
    }, []);

  return (
    <>
        <div className="pagetitle">
            <h1>Gas Orders</h1>
            <nav>
            <ol className="breadcrumb">
                <li className="breadcrumb-item">
                    <NavLink to="/app/user/dashboard">Dashboard</NavLink>
                </li>
                <li className="breadcrumb-item">
                    <NavLink to="/app/supplier/order">Order</NavLink>
                </li>
                <li className="breadcrumb-item active">Requests</li>
            </ol>
            </nav>
        </div>
        <section className="section contact">
            <div className="row gy-4">
                <div className="col-xl-12">
                    <div className="row">
                        {pendingOrder.length > 0 ? 
                             pendingOrder.map(order => (
                                <div className="col-lg-3"  key={order.id}>
                                    <div className="card">
                                        <div className="card-body order">
                                            <div className="d-flex justify-content-between  order-head">
                                                <h4>{order.quantity}kg</h4>
                                                <p className={`${order.priority == 'high' ? 
                                                'text-danger' : order.priority == 'medium' ? 
                                                'text-success' : 'text-warning'} text-capitalize` }>{order.priority}</p>
                                            </div>
                                            <h3>{order.customer?.last_name} {order.customer?.first_name}</h3>
                                            <p  className='text-muted'>{order.delivery_address}</p>
                                            <p>{order.phone}</p>
                                            <div className="d-flex justify-content-between align-items-center mt-2">
                                                <span>
                                                    <b className='text-primary order-amount'>₦{Number(order.order_amount).toLocaleString()}</b>
                                                    <div className="d-flex align-items-center order-delivery">
                                                        <p className='me-2'><i className='bi bi-pin-map  me-1'></i> {order.delivery_distance} km</p>
                                                        <p><i className='bi bi-clock me-1'></i> {order.delivery_time}</p>
                                                    </div>
                                                </span>
                                                <div>
                                                    <button className='btn btn-info btn-sm text-white me-2' onClick={() => showDetailsMap(order)}>Map</button>
                                                    <button className='btn btn-primary btn-sm text-white' onClick={() => openOrderAccept(order)}>Accept</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>))
                            :
                                <div className='col-lg-12 d-flex align-items-center justify-content-center' style={{height: '70vh'}}>
                                    <h3>Waiting for Gas Orders...</h3>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </section>

        {/* confirm Order acceptance */}
        <>
            <Dialog
                open={acceptOpen}
                onClose={closeOrderAccept}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {loading && <Loader />}
                <DialogTitle id="alert-dialog-title">
                    Accept <b className="text-primary">{currentOrder?.quantity}kg</b> Gas order
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to perform this operation?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className='text-danger' onClick={closeOrderAccept}>Cancel</Button>
                    <Button onClick={handleOrderAccept} autoFocus>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
        </>

        {/* View map Modal */}
        {showMap &&
            <ViewMap 
                show={showMap}
                closeModal={closeModal}
                currentOrder={currentOrder}
                customerLocation={currentOrder.delivery_location}
                supplierLocation={userData.userInfo.location}
            />
        }

        {/* View map Modal */}
        {showDeliveryVehicle &&
            <DeliveryVehicle 
                show={showDeliveryVehicle}
                closeModal={closeModal}
                currentOrder={currentOrder}
            />
        }
    </>
  )
}
