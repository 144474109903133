import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";

import "../../Supplier/Vehicle/CreateVehicle.css";
import { toast } from "react-toastify";
import axiosInstance from "../../../../hooks/axiosInstance";
import Loader from "../../../Utilities/Loader/Loader";

export default function ViewBankAccounts({ show, closeModal, currentUser}) {
  const [loading, setLoading] = useState(false)
  const [bankAccounts, setBankAccounts]  = useState([])

  const handleClose = () => {
    closeModal();
  };

  const fetchSupplierBanks = () => {
    setLoading(true);
    axiosInstance
      .get(
        `/admin/bank_account/supplier/${currentUser.id}`
      )
      .then((response) => {
        setBankAccounts(response.data.data.items);
      })
      .catch((err) => {
        toast.error("An error occured. Please, try again");
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchSupplierBanks()
  }, [currentUser])

  return (
    <div className="ViewTransaction">
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title><span className="text-primary">{currentUser?.last_name} {currentUser?.first_name}</span> Bank Accounts</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {bankAccounts && bankAccounts.length > 0 ?
                    <>
                        {bankAccounts.map(account => (
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title>{account?.bank_name}</Card.Title>
                                    <Card.Text>
                                        <div className="d-flex justify-content-between">
                                            <p><b>Account Name</b></p>
                                            <p>{account?.account_name}</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <p><b>Account Number</b></p>
                                            <p>{account?.account_number}</p>
                                        </div>
                                    </Card.Text>
                                </Card.Body>
                            </Card> 
                        ))}
                    </>
                :
                    <div className="alert alert-info">
                        <h4>No order payment account found</h4>
                    </div>
                }  
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
        {loading && <Loader />}
    </div>
  );
}
